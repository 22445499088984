import React from 'react'
import './banner.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const index = () => {
  return (
    <Carousel responsive={responsive}  infinite={true} showDots={true} draggable={false}>

    <div className='banner'>
      <div className='banner-left'>
        <h1 className='banner-head'>Streaming The Way You Like!</h1>
        <h3 className='banner-subhead'>One Subscription. Many OTT's. Multiple Devices.</h3>
        <div className='banner-watcho-devices'>
            <div className=''>
                <p>Watch on any device</p>
            </div>
            <ul className='list-devices'>
                <li>
                    <img src={require('../../assets/images/icons/tv.png')} alt="Tv" />
                    <div className='deviceName'>TV</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/desktop.png')} alt="Tv" />
                    <div className='deviceName'>Desktop</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/mobile.png')} alt="Tv" />
                    <div className='deviceName'>Mobile</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/laptop.png')} alt="Tv" />
                    <div className='deviceName'>Laptop</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/tablet.png')} alt="Tv" />
                    <div className='deviceName'>Tablet</div>
                </li>
            </ul>
        </div>
      </div>
      <div className='banner-right'> 
        <img src={require('../../assets/images/banner/banner.png')} />
      </div>
    </div>
    
    <div className='banner'>
      <div className='banner-left'>
        <h1 className='banner-head'>Streaming The Way You Like!</h1>
        <h3 className='banner-subhead'>One Subscription. Many OTT's. Multiple Devices.</h3>
        <div className='banner-watcho-devices'>
            <div className=''>
                <p>Watch on any device</p>
            </div>
            <ul className='list-devices'>
                <li>
                    <img src={require('../../assets/images/icons/tv.png')} alt="Tv" />
                    <div className='deviceName'>TV</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/desktop.png')} alt="Tv" />
                    <div className='deviceName'>Desktop</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/mobile.png')} alt="Tv" />
                    <div className='deviceName'>Mobile</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/laptop.png')} alt="Tv" />
                    <div className='deviceName'>Laptop</div>
                </li>
                <li>
                    <img src={require('../../assets/images/icons/tablet.png')} alt="Tv" />
                    <div className='deviceName'>Tablet</div>
                </li>
            </ul>
        </div>
      </div>
      <div className='banner-right'> 
        <img src={require('../../assets/images/banner/banner1.png')} />
      </div>
    </div>
</Carousel>

  )
}

export default index
